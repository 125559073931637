const CONSTANTS = {
    NOT_AVAILABLE: "N/A",

    JWT: "JWT",

    API_HOST: process.env.REACT_APP_API_URL || "http://api.jaroplayer.com/",

    REACT_APP_WEB_DOMAIN: process.env.REACT_APP_WEB_DOMAIN || "https://www.jaroplayer.com/",

    APP_NAME: process.env.REACT_APP_APPLICATION_NAME || "JARO PLAYER WEB",

    APP_COLOR: "black",
};

export default CONSTANTS;
